.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@keyframes shimmerDark {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.App-header {
  background-color: #282c34;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  background-color: black;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: black;
  border-radius: 5px;
}

input[type="checkbox"]:checked::after {
  content: '✔';
  color: white;
  display: block;
  text-align: center;
  position: relative;
  top: -2px;
}

input[type="checkbox"]:hover {
  border-color: grey;
}